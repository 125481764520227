<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>

    <section class="section section-press-header">
      <div class="container m-container-only-s">
        <div class="table">
          <div class="table-cell">
            <img :src="getImage(item.image)" alt="" />
          </div>
          <div class="table-cell">
            <strong>{{ item.title }}</strong>
            <span>{{ item.issue_number }}</span>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-press section-text">
      <div class="container m-container-only-s">
        <div class="press-list">
          <div class="large-item">
            <div class="img-container">
              <img :src="getImage(item.image)" alt="" />
            </div>
            <h1 class="headline">{{ item.title }}</h1>
            <p class="second-headline">
              {{ item.description }}
            </p>
          </div>

          <div class="item" :key="index" v-for="(article, index) in item.items">
            <div class="img">
              <img :src="getImage(article.image)" alt="" />
            </div>
            <div class="description">
              <router-link :to="`/press/${number}/${article.id}`">
                <h3>{{ article.title }}</h3>
                <p v-html="article.description"></p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "PressNumber",
  props: ["number"],
  title() {
    return this.$t("pages.press");
  },
  data: () => {
    return {
      item: {}
    };
  },
  async mounted() {
    const { data } = await this.axios.get(
      "/storage/press/" + this.number + "/index.json"
    );
    this.item = data;
  },
  methods: {
    getImage(image) {
      return `/storage/${image}`;
    }
  }
};
</script>

<style scoped></style>
